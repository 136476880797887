/* VARIABLES
---------------------------------------------- */
:root {
  /* Fonts */
  --body-font: 'Tiempos Text', serif;
  --heading-font: 'Tiempos Headline', serif;
  --sans-serif-font: 'Calibre', sans-serif;

  /* Font sizes */
  --font-size: 16px;
  --tiny-font-size: .8rem;
  --xx-small-font-size: .9rem;
  --x-small-font-size: 1.1rem;
  --small-font-size: 1.2rem;
  --medium-font-size: clamp(1.3rem, 2.5vw, 1.5rem);
  --preheading-font-size: 1.6rem;
  --large-font-size: clamp(2rem, 3vw, 2.2rem);
  --x-large-font-size: clamp(2.5rem, 4.5vw, 3.5rem);
  --xx-large-font-size: clamp(2.8rem, 1.6rem + 3.2vw, 4.2rem);
  --xxx-large-font-size: clamp(3.2rem, 1.914rem + 3.429vw, 4.7rem);
  --huge-font-size: clamp(4.2rem, 3.129rem + 2.857vw, 5.7rem); /* Blog page titles */ 

  /* Line height */
  --standard-line-height: 1.35;

  /* Vertical margins */
  --xx-small-vertical-margin: .2rem;
  --x-small-vertical-margin: 1rem;
  --small-vertical-margin: 3rem;
  --medium-vertical-margin: 5rem;
  --large-vertical-margin: 9rem;
  --x-large-vertical-margin: 12rem;
  --xx-large-vertical-margin: 16rem;

  /* Colors */
  /*--off-black: #3a3a3a;
  --off-black: #333333;
  --off-black: #1c1c1c;
  --off-black: #1a1a1a; */
  --black: #20201e;
  --white: white;
  /* --light-grey: #deddd9; */
  --grey: #e8e7e4; 
  --mediumgrey: #DBDBD5;
  --darkgrey: #8a8a8a;
  --cream: #E5E4E0;
  --dot-color: #e5e4e0;
  --green: rgba(63, 127, 101, 1);
  --blue: ;
  --purple: ;
  --border-color: var(--grey);

  /* Widths, paddings, gaps */
  /* --vh: 1; */
  --small-width: 500px;
  --medium-width: 650px;
  --large-width: 750px;
  --x-large-width: 800px;
  --xx-large-width: 990px;
  --page-width: 1300px;
  --lr-page-padding: 40px;
  --medium-grid-gap: clamp(1.8rem, 4.5vw, 4.5rem);
  --large-grid-gap: calc(var(--lr-page-padding)*3);

  /* Logos animation */
  --duration: 60s;
  --gap: 60px;
  --scroll-start: 0;
  --scroll-end: calc(-100% - var(--gap));

  /* Set up */
  font-family: var(--body-font);
  font-size: var(--font-size);
  color: var(--black);
}

/* MOBILE CSS VARIABLES
---------------------------------------------- */
@media (max-width: 599px) {
  :root {
  }
}

/* VARIABLES - for-large-tablet-portrait-below
---------------------------------------------- */
@include for-large-tablet-portrait-below {
  :root {
    /* Font sizes */
    --tiny-font-size: .7rem;
    --xx-small-font-size: .8rem;
    --x-small-font-size: .9rem;
    --small-font-size: 1rem;
    --medium-font-size: clamp(1rem, 0.857rem + 0.381vw, 1.2rem);
    --preheading-font-size: 1.2rem;
    --large-font-size: clamp(1.2rem, 1.057rem + 0.381vw, 1.4rem);
    --x-large-font-size: clamp(1.6rem, 1.029rem + 1.524vw, 2.4rem);
    --xx-large-font-size: clamp(2.2rem, 1.057rem + 3.048vw, 3.8rem);
    --xxx-large-font-size: clamp(2.5rem, 1.429rem + 2.857vw, 4rem);
    --huge-font-size: clamp(3rem, 1.929rem + 2.857vw, 4.5rem); /* Blog page titles */ 

    /* Vertical margins */
    --xx-small-vertical-margin: .13rem;
    --x-small-vertical-margin: .66rem;
    --small-vertical-margin: 2rem;
    --medium-vertical-margin: 3.3rem;
    --large-vertical-margin: 6rem;
    --x-large-vertical-margin: 8rem;
    --xx-large-vertical-margin: 10.5rem;

    /* Widths, paddings, gaps */
    /* --vh: 1; */
    --lr-page-padding: 20px;
    --medium-grid-gap: clamp(1.8rem, 4.5vw, 4.5rem);
    --large-grid-gap: calc(var(--lr-page-padding)*3);

    /* Logos animation */
    --duration: 50s;
    --gap: 30px;
  }
}